import { Outlet } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Layout = () => {
  return (
    <>  
        <div class="flex flex-col min-h-screen">
          <Header />
          <Outlet className="flex-grow" />
          {/* pin the footer to the bottom of the page */}
          <Footer />
        </div>
    </>
  )
};

export default Layout;