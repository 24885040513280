import { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { HiMenuAlt3 } from 'react-icons/hi';
import { FaTimes, FaInstagram, FaTiktok, FaYoutube } from 'react-icons/fa';
import { SignedIn, SignedOut, UserButton, SignInButton } from "@clerk/clerk-react";
import { SignIn, SignUp } from "@clerk/clerk-react";
import ReactModal from 'react-modal';
import { EmailAlerts } from './EmailAlerts';
import { BillingPage } from './BillingPage';
import './styles.css';  // Import the CSS file
import { UserButtonConfig } from './UserButtonConfig';

ReactModal.setAppElement('#root');

function Header() {
  const [toolsDropdownOpen, setToolsDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  const dropdownRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setToolsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    setIsMobileMenuOpen(false);
    setToolsDropdownOpen(false);
  }, [location]);

  function toggleToolsDropdown() {
    setToolsDropdownOpen(!toolsDropdownOpen);
  }

  function toggleMobileMenu() {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  }

  function openModal(isLogin) {
    setIsLogin(isLogin);
    setModalIsOpen(true);
  }

  function closeModal() {
    setModalIsOpen(false);
  }

  const AlertIcon = () => {
    return (
      <div className="flex items-center justify-center h-full w-full">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="h-full w-full"  // Changed to fill the available space
        >
          <path d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm6-6v-5c0-3.07-1.63-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.64 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2zm-2 1H8v-6c0-2.48 1.51-4.5 4-4.5s4 2.02 4 4.5v6z" />
        </svg>
      </div>
    )
  }

  const BillingIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        className="h-4 w-4"
      >
        <path d="M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4v-6h16v6zm0-10H4V6h16v2z" />
      </svg>
    )
  }


  return (
    <header className="bg-black py-4 px-6 flex justify-between items-center">
      <Link to="/" className="text-white text-2xl font-bold">
        <img src="/LogoV2.png" alt="FlightFormula✈" className="h-10"/>
      </Link>

      <div className="flex items-center">
        <div className="md:hidden">
          <button
            type="button"
            className="text-white hover:text-gray-200 focus:text-gray-200 mr-2"
            onClick={toggleMobileMenu}
          >
            {isMobileMenuOpen ? <FaTimes /> : <HiMenuAlt3 />}
          </button>
        </div>

        <nav
          className={`${
            isMobileMenuOpen ? '' : 'hidden'
          } md:block md:items-center w-full md:w-auto`}
        >
          <div className="md:flex-grow">
            <Link
              to=""
              className="block text-white font-semibold hover:text-gray-300 mr-2 py-2 md:inline-block"
            >
              Home
            </Link>

            <Link
              to="/posts"
              className="block text-white font-semibold hover:text-gray-300 mr-2 py-2 md:inline-block"
            >
              Posts
            </Link>

            <button
              type="button"
              className="text-white font-semibold hover:text-gray-300 mr-2 py-2 md:inline-block"
              onClick={toggleToolsDropdown}
            >
              Tools
            </button>

            <Link
              to="contact"
              className="block text-white font-semibold hover:text-gray-300 mr-2 py-2 md:inline-block"
            >
              Contact
            </Link>

            <div className="hidden md:block md:inline-block md:border-l-2 md:border-gray-200 md:mr-2 md:h-3"></div>
            
            <div className="flex md:inline-block py-2">
              <a
                href="https://www.instagram.com/flightformula/"
                target="_blank"
                rel="noreferrer"
                className="text-white hover:text-gray-300 mr-2 md:inline-block"
              >
                <FaInstagram />
              </a>
              <a
                href="https://www.tiktok.com/@flightformula"
                target="_blank"
                rel="noreferrer"
                className="text-white hover:text-gray-300 mr-2 md:inline-block"
              >
                <FaTiktok />
              </a>
              <a
                href="https://www.youtube.com/@flightformula"
                target="_blank"
                rel="noreferrer"
                className="text-white mr-4 hover:text-gray-300 md:inline-block"
              >
                <FaYoutube />
              </a>
            </div>
            <SignedIn>
              <UserButtonConfig />
            </SignedIn>
            <SignedOut>
              <SignInButton mode="modal" signUpForceRedirectUrl="/setup-account">
                <a href="#" className="inline-block px-4 py-2 text-white bg-transparent border border-white rounded-full hover:bg-white hover:text-purple-600 transition duration-200">Log In</a>
              </SignInButton>
            </SignedOut>
          </div>
          {toolsDropdownOpen && (
            <div
              ref={dropdownRef}
              className="absolute right-0 mt-2 w-56 bg-white rounded-md shadow-xl py-1 z-10"
            >
              <Link
                to="tools/qfflightfinder"
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >
                Qantas Flight Finder
              </Link>
              <Link
                to="tools/qfwine"
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >
                Qantas Wine Tracker
              </Link>
            </div>
          )}
          
        </nav>
      </div>

      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Authentication Modal"
        className="modal"
        overlayClassName="overlay"
      >
        {isLogin ? (
          <LogInPage switchToSignUp={() => setIsLogin(false)} />
        ) : (
          <SignUpPage switchToLogin={() => setIsLogin(true)} />
        )}
        {/* line across the modal */}
        <hr className="my-0" />
        {/* add a purple close bar button (rounded) that covers the full width of the modal at the bottom of the modal */}
        <button className="relative mt-4 bottom-0 w-full bg-purple-600 text-white py-1 rounded-lg" onClick={closeModal}>
          Close
        </button>
      </ReactModal>
    </header>
  );
}

function LogInPage({ switchToSignUp }) {
  return (
    <div className="flex items-start justify-center bg-white">
      <div className="mt-2">
        <SignIn
          forceRedirectUrl="/account"
          appearance={{
            elements: {
              logoBox: 'hidden',
              card: 'w-full max-w-md p-4 md:p-8',
            },
          }}
        />
        <div className="text-center mt-4 mb-4">
          <span>Don't have an account? </span>
          <button onClick={() => window.location.href = '/setup-account'} className="text-black border border-black rounded-full px-4 py-1 hover:bg-black hover:text-white md:ml-auto">
            Sign Up
          </button>
        </div>
      </div>
    </div>
  );
}

function SignUpPage({ switchToLogin }) {
  return (
    <div className="flex items-center justify-center bg-white">
      <div className="max-w-md w-full p-4">
        <h1 className="text-2xl font-bold text-center">Sign Up (it costs money!)</h1>
        <p className="text-lg text-center mt-2">Subscribe to access wine tracker email alerts and SeatMagnet, our new and improved reward seat tracker tool.</p>
        
        <div className="mt-4">
          <SignUp
            signUpForceRedirectUrl="/setup-account"
            appearance={{
              elements: {
                logoBox: 'hidden',
                card: 'w-full max-w-md p-4 md:p-8',
              },
            }}
          />
        </div>
        
        <div className="text-center mt-4">
          <span>Already have an account? </span>
          <button onClick={switchToLogin} className="text-black border border-black rounded-full px-9 py-1 hover:bg-black hover:text-white">
            Log In
          </button>
        </div>
      </div>
    </div>
  );
}

export default Header;