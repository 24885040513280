import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth, UserProfile, UserButton } from '@clerk/clerk-react';
import { EmailAlerts } from './EmailAlerts';
import { BillingPage } from './BillingPage';

const AlertIcon = () => (
  <div className="flex items-center justify-center h-full w-full">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className="h-full w-full"
    >
      <path d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm6-6v-5c0-3.07-1.63-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.64 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2zm-2 1H8v-6c0-2.48 1.51-4.5 4-4.5s4 2.02 4 4.5v6z" />
    </svg>
  </div>
);

const BillingIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    className="h-4 w-4"
  >
    <path d="M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4v-6h16v6zm0-10H4V6h16v2z" />
  </svg>
);

export function AccountPage() {
  const { isSignedIn, isLoaded } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoaded && !isSignedIn) {
      navigate('/setup-account');
    }
  }, [isSignedIn, isLoaded, navigate]);

  if (!isLoaded || !isSignedIn) {
    return null;
  }

  return (
    <div className="py-12 flex items-center justify-center">
        <UserProfile>
            <UserButton.UserProfilePage label="account" />
            <UserButton.UserProfilePage label="security" />
            <UserButton.UserProfilePage 
                label="Alert Settings" 
                url="alert-settings"
                labelIcon={<AlertIcon />}
            >
                <EmailAlerts />
            </UserButton.UserProfilePage>
            <UserButton.UserProfilePage 
                label="Billing" 
                url="billing"
                labelIcon={<BillingIcon />}
            >
                <BillingPage />
            </UserButton.UserProfilePage>
        </UserProfile>
    </div>
  );
}