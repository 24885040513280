import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth, SignIn } from '@clerk/clerk-react';
import { SignInButton } from '@clerk/clerk-react';

export function SignInPage() {
  const { isSignedIn } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isSignedIn) {
      navigate('/');
    }
  }, [isSignedIn, navigate]);

  if (isSignedIn) {
    return null;
  }

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="w-full max-w-md">
        <SignIn />
      </div>
    </div>
  );
}