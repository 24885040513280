import { SignedIn, SignedOut, useUser, SignUpButton, SignInButton, useClerk } from "@clerk/clerk-react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

function SetupAccount() {
    const { isLoaded, isSignedIn, user } = useUser();
    const [subscriptionStatus, setSubscriptionStatus] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [metadata, setMetadata] = useState({});
    const navigate = useNavigate();
    const clerk = useClerk();

    const [formData, setFormData] = useState({
        email: metadata.email || '',
        phoneCountryCode: '+61', // Default to Australia
        phone: '',
        wine_alerts: false,
        sms_alerts: false,
        wine_threshold: '2.0',
        same_wine_day_interval: '7',
    });

    const [showSmsWarning, setShowSmsWarning] = useState(false);
    const [phoneError, setPhoneError] = useState('');

    const checkSubscription = async () => {
        if (isLoaded && isSignedIn && user) {
            try {
                const response = await fetch('https://ff-users.flightformula.workers.dev/check-subscription', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ userId: user.id }),
                });
                const data = await response.json();
                setSubscriptionStatus(data.status);
                setMetadata(data.publicMetadata);
                return data.status === 'subscription_active';
            } catch (error) {
                console.error('Error checking subscription:', error);
                return false;
            }
        }
        return false;
    };

    const handleRedirectToStripe = (user) => {
        const userEmail = user.primaryEmailAddress?.emailAddress;
        const encodedEmail = encodeURIComponent(userEmail);
        const baseUrl = process.env.REACT_APP_STRIPE_BASE_URL; // Get the base URL from environment variables
        const stripePaymentLink = `${baseUrl}?prefilled_email=${encodedEmail}&client_reference_id=${user.id}`;
        window.location.href = stripePaymentLink;
    };

    useEffect(() => {
        const checkAndRedirect = async () => {
            if (isLoaded && isSignedIn && user) {
                const isSubscribed = await checkSubscription();
                if (!isSubscribed) {
                    handleRedirectToStripe(user);
                } else {
                    setIsLoading(false);
                }
            } else if (isLoaded && !isSignedIn) {
                setIsLoading(false);
            }
        };

        checkAndRedirect();
    }, [isLoaded, isSignedIn, user])

    useEffect(() => {
        if (isLoaded && isSignedIn && user) {
            setFormData(prevData => ({
                ...prevData,
                email: user.primaryEmailAddress?.emailAddress || '',
                ...metadata
            }));
        }
    }, [isLoaded, isSignedIn, user, metadata]);

    const isValidEmail = (email) => {
        // Basic email validation regex
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const isValidPhone = (phoneNumber) => {
        const phoneRegex = /^\d{10}$/;
        return phoneRegex.test(phoneNumber);
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (name === 'phone') {
            // Allow only numeric input
            const numericValue = value.replace(/\D/g, '');
            setFormData(prevData => ({
                ...prevData,
                [name]: numericValue
            }));
            
            // Validate phone number
            if (numericValue.length > 0 && !isValidPhone(numericValue)) {
                setPhoneError('Please enter a valid 10-digit mobile number.');
            } else {
                setPhoneError('');
            }
        } else {
            setFormData(prevData => ({
                ...prevData,
                [name]: type === 'checkbox' ? checked : value
            }));
        }
    };

    const handleToggleChange = (e) => {
        const { name, checked } = e.target;
        if (name === 'wine_alerts' && checked && !isValidEmail(formData.email)) {
            alert('Please enter a valid email address before enabling wine alerts.');
            return;
        }
        if (name === 'sms_alerts' && checked && !isValidPhone(formData.phone)) {
            setShowSmsWarning(true);
            return;
        }
        setFormData(prevData => ({
            ...prevData,
            [name]: checked
        }));
        if (name === 'sms_alerts' && !checked) {
            setShowSmsWarning(false);
        }
    };

    const handleSaveSettings = async (e) => {
        e.preventDefault();
        if (formData.sms_alerts && !isValidPhone(formData.phone)) {
            setPhoneError('Please enter a valid 10-digit mobile number to enable SMS alerts.');
            return;
        }
        setIsSaving(true);
        try {
            const response = await fetch('https://ff-users.flightformula.workers.dev/update-metadata', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userId: user.id, metadata: formData }),
            });
            const data = await response.json();
            if (data.success) {
                // Redirect to the QF Wine tool page
                navigate('/tools/qfwine');
            } else {
                alert('Failed to save settings. Please try again.');
            }
        } catch (error) {
            console.error('Error saving settings:', error);
            alert('An error occurred. Please try again.');
        } finally {
            setIsSaving(false);
        }
    };

    const handleSignUpSuccess = async () => {
        // Wait for the user object to be available
        const user = await clerk.client.user.getCurrent();
        if (user) {
            handleRedirectToStripe(user);
        }
    };

    const MobileImageCarousel = () => (
        <Carousel showThumbs={false} showStatus={false} infiniteLoop autoPlay>
            <div>
                <img src="https://images.flightformula.com/HQ-EMAIL.jpg" alt="Email Alert" className="w-full h-auto object-cover" />
            </div>
            <div>
                <img src="https://images.flightformula.com/HQ-SMS.jpg" alt="SMS Alert" className="w-full h-auto object-cover" />
            </div>
            <div>
                <img src="https://images.flightformula.com/HQ-UI.jpg" alt="Wine Deals" className="w-full h-auto object-cover" />
            </div>
        </Carousel>
    );

    return (
        <div className="flex flex-col">
            <SignedOut>
                <div className="flex-grow p-2 md:p-8 bg-white text-gray-800 min-h-screen">
                    <div className="container mx-auto max-w-full">                        
                        <div className="flex flex-col md:flex-row">
                            <div className="md:w-1/3 space-y-4 md:space-y-6 pr-0 md:pr-4">
                                <h1 className="text-5xl md:text-5xl font-bold text-left mb-2 md:mb-4 text-black">WineTracker<span className="text-purple-600">+</span></h1>
                                <p className="text-xl mb-4 md:mb-6 text-gray-600">Upgrade your wine experience and support Flight Formula!</p>

                                <ul className="space-y-2 md:space-y-4 text-lg">
                                    <li className="flex items-center">
                                        <svg className="w-6 h-6 mr-2 text-purple-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                                        Email Alerts
                                    </li>
                                    <li className="flex items-center">
                                        <svg className="w-6 h-6 mr-2 text-purple-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                                        SMS Alerts (Australian numbers only)
                                    </li>
                                    <li className="flex items-center">
                                        <svg className="w-6 h-6 mr-2 text-purple-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                                        Access to Every Wine Deal
                                    </li>
                                    <li className="flex items-center">
                                        <svg className="w-6 h-6 mr-2 text-purple-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                                        Advanced Filtering (Reds & Whites)
                                    </li>
                                    <li className="flex items-center">
                                        <svg className="w-6 h-6 mr-2 text-purple-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                                        More Frequent Updates
                                    </li>
                                    <li className="flex items-center">
                                        <svg className="w-6 h-6 mr-2 text-purple-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                                        Support Flight Formula's Development
                                    </li>
                                </ul>

                                <div className="mt-6 md:mt-8">
                                    <div className="bg-purple-100 border border-purple-200 rounded-lg p-3 md:p-4 mb-4 md:mb-6 text-center">
                                        <p className="text-2xl font-extrabold text-purple-600">$7.47 <span className="text-sm">/month</span></p>
                                        <p className="text-gray-500 my-2">or</p>
                                        <p className="text-2xl font-extrabold text-purple-600">$60.00 <span className="text-sm">/year</span></p>
                                        <p className="text-lg font-semibold text-purple-600 mt-2">(Save 33% - $5.00<span className="text-sm">/month</span>)</p>
                                    </div>                                    
                                    <SignUpButton mode="modal" afterSignUpUrl={window.location.href} onSignUpSuccess={handleSignUpSuccess}>
                                        <button 
                                            className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-lg font-medium text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 mb-4 transition-all duration-300 ease-in-out transform hover:scale-105"
                                            onClick={() => clerk.openSignUp({ afterSignUpUrl: window.location.href })}
                                        >
                                            Subscribe to WineTracker+
                                        </button>
                                    </SignUpButton>

                                    <p className="mt-2 md:mt-4 text-center text-sm text-gray-600">
                                        Already have an account? 
                                        <SignInButton mode="modal">
                                            <a href="#" className="font-medium text-purple-600 hover:text-purple-500 ml-1">Sign in</a>
                                        </SignInButton>
                                    </p>
                                </div>
                            </div>
                            
                            <div className="md:w-2/3 mt-6 md:mt-0">
                                <div className="md:hidden">
                                    <MobileImageCarousel />
                                </div>
                                <div className="hidden md:grid grid-cols-3 gap-6">
                                    <img src="https://images.flightformula.com/HQ-EMAIL.jpg" alt="Email Alert" className="w-full h-auto object-cover" />
                                    <img src="https://images.flightformula.com/HQ-SMS.jpg" alt="SMS Alert" className="w-full h-auto object-cover" />
                                    <img src="https://images.flightformula.com/HQ-UI.jpg" alt="Wine Deals" className="w-full h-auto object-cover" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </SignedOut>
            <SignedIn>
                {isLoading ? (
                    <div className="p-4 md:p-16 text-center">
                        <p>Loading...</p>
                    </div>
                ) : subscriptionStatus === 'subscription_active' ? (
                    <div className="p-4 md:p-16 max-w-2xl mx-auto">
                        <h1 className="text-3xl font-bold mb-6 text-center">Welcome to WineTracker<span className="text-purple-600">+</span></h1>
                        <p className="text-lg text-gray-600 mb-8 text-center">Let's set up your alerts to ensure you never miss a great wine deal.</p>
                        <form onSubmit={handleSaveSettings} className="space-y-8">
                            <div className="bg-white p-6 rounded-lg shadow-md">
                                <h2 className="text-xl font-semibold mb-4">Contact Information</h2>
                                <div className="space-y-4">
                                    <div>
                                        <label htmlFor="email" className="block mb-1 font-medium">Email</label>
                                        <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleInputChange}
                                            className="w-full p-2 border rounded"
                                            required
                                        />
                                        <p className="text-sm text-gray-500 mt-1">We'll send wine deal alerts to this email address.</p>
                                    </div>

                                    <div>
                                        <label htmlFor="phone" className="block mb-1 font-medium">Mobile</label>
                                        <div className="flex">
                                            <div className="w-24 p-2 border rounded-l bg-gray-100 text-gray-700 font-medium">
                                                +61 (AU)
                                            </div>
                                            <input
                                                type="tel"
                                                id="phone"
                                                name="phone"
                                                value={formData.phone}
                                                onChange={handleInputChange}
                                                className={`flex-grow p-2 border rounded-r ${phoneError ? 'border-red-500' : ''}`}
                                                placeholder="Enter your 10-digit mobile number"
                                                maxLength="10"
                                            />
                                        </div>
                                        {phoneError && <p className="text-sm text-red-500 mt-1">{phoneError}</p>}
                                        <p className="text-sm text-gray-500 mt-1">Optional: For receiving SMS alerts about wine deals. Currently only available for Australian mobile numbers.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="bg-white p-6 rounded-lg shadow-md">
                                <h2 className="text-xl font-semibold mb-4">Alert Preferences</h2>
                                <p className="text-sm text-gray-500 mb-4">Theoretically, both are optional, but then you'd be missing out on some great deals!</p>
                                <div className="space-y-4">
                                    <div className="flex items-center justify-between">
                                        <div>
                                            <span className="font-medium">Enable Email Alerts</span>
                                            <p className="text-sm text-gray-500">Receive wine deal notifications via email</p>
                                        </div>
                                        <label className="relative inline-flex items-center cursor-pointer">
                                            <input
                                                type="checkbox"
                                                name="wine_alerts"
                                                checked={formData.wine_alerts}
                                                onChange={handleToggleChange}
                                                className="sr-only peer"
                                                disabled={!isValidEmail(formData.email)}
                                            />
                                            <div className={`w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600 ${!isValidEmail(formData.email) ? 'opacity-50 cursor-not-allowed' : ''}`}></div>
                                        </label>
                                    </div>

                                    <div className="flex items-center justify-between">
                                        <div>
                                            <span className="font-medium">Enable SMS Alerts</span>
                                            <p className="text-sm text-gray-500">Get instant notifications via text message</p>
                                        </div>
                                        <label className="relative inline-flex items-center cursor-pointer">
                                            <input
                                                type="checkbox"
                                                name="sms_alerts"
                                                checked={formData.sms_alerts}
                                                onChange={handleToggleChange}
                                                className="sr-only peer"
                                            />
                                            <div className={`w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600`}></div>
                                        </label>
                                    </div>
                                    {showSmsWarning && !isValidPhone(formData.phone) && (
                                        <p className="text-sm text-red-500 mt-1">Please enter a valid phone number to enable SMS alerts.</p>
                                    )}
                                </div>
                            </div>

                            <div className="bg-white p-6 rounded-lg shadow-md">
                                <h2 className="text-xl font-semibold mb-4">Alert Settings</h2>
                                <div className="space-y-4">
                                    <div>
                                        <label htmlFor="wineThreshold" className="block mb-1 font-medium">Wine Deal Threshold</label>
                                        <select
                                            id="wine_threshold"
                                            name="wine_threshold"
                                            value={formData.wine_threshold}
                                            onChange={handleInputChange}
                                            className="w-full p-2 border border-gray-300 rounded"
                                        >
                                            {Array.from({ length: 11 }, (_, i) => (1.5 + i * 0.1).toFixed(1)).map(value => (
                                                <option key={value} value={value}>{value} cents per point / {(100 / parseFloat(value)).toFixed(2)} points per dollar</option>
                                            ))}
                                        </select>
                                        <p className="text-sm text-gray-500 mt-1">You'll receive alerts for wine deals with a cents per point value equal to or below this threshold.</p>
                                    </div>

                                    <div>
                                        <label htmlFor="sameWineDayInterval" className="block mb-1 font-medium">Repeat Alert Interval</label>
                                        <select
                                            id="same_wine_day_interval"
                                            name="same_wine_day_interval"
                                            value={formData.same_wine_day_interval}
                                            onChange={handleInputChange}
                                            className="w-full p-2 border border-gray-300 rounded"
                                        >
                                            <option value="14">Every 14 days</option>
                                            <option value="30">Every 30 days</option>
                                            <option value="never">Never repeat</option>
                                        </select>
                                        <p className="text-sm text-gray-500 mt-1">How often you'll receive alerts for the same wine deal if it's still available.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="text-center">
                                <button 
                                    type="submit" 
                                    className={`
                                        bg-purple-600 text-white px-8 py-3 rounded-lg text-lg font-semibold
                                        hover:bg-purple-700 transition-all duration-300 ease-in-out
                                        transform hover:scale-105 active:scale-95
                                        ${isSaving ? 'opacity-75 cursor-not-allowed' : ''}
                                    `}
                                    disabled={isSaving}
                                >
                                    {isSaving ? (
                                        <span className="flex items-center justify-center">
                                            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                            Saving...
                                        </span>
                                    ) : (
                                        'Finish Setup'
                                    )}
                                </button>
                                <p className="text-sm text-gray-600 mt-4">Don't worry, you can always update these settings later from your account page.</p>
                            </div>
                        </form>
                    </div>
                ) : null}
            </SignedIn>
        </div>
    );
}

export default SetupAccount;

