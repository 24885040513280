import React from 'react';

const Footer = () => {
    return (
        <footer className="p-4 text-center bg-gray-100">
            <div className="flex flex-col items-center sm:flex-row sm:justify-center">
                <div className="mb-3 sm:mb-0 sm:mr-4 text-gray-600">
                    FlightFormula &copy; {new Date().getFullYear()}
                </div>
                <div className="flex flex-col sm:flex-row items-center">
                    <span className="hidden sm:inline text-gray-400 mx-2">|</span>
                    <a href="/pp" className="mb-2 sm:mb-0 sm:mx-2 text-purple-600 hover:text-purple-800 hover:underline transition-colors duration-200">Privacy Policy</a>
                    <span className="hidden sm:inline text-gray-400 mx-2">|</span>
                    <a href="/tos" className="text-purple-600 hover:text-purple-800 hover:underline transition-colors duration-200">Terms of Service</a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;