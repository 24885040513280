import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="max-w-3xl mx-auto px-4 py-8 font-sans text-gray-800">
      <h1 className="text-4xl font-bold text-purple-900 border-b-2 border-purple-500 pb-2 mb-6">Privacy Policy for FlightFormula</h1>
      <p className="text-sm text-gray-600 mb-6">Last Updated: 5th of September 2024</p>

      <p className="mb-6">Welcome to the FlightFormula Privacy Policy! We know, privacy policies aren't exactly a page-turner, but stick with us. We'll keep it simple, straightforward, and maybe even a little entertaining. By using FlightFormula, you agree to this Privacy Policy. If you don't, then no worries—you can fly elsewhere.</p>

      <h2 className="text-2xl font-semibold text-purple-700 mt-8 mb-4">1. What Information We Collect</h2>
      <p className="mb-4">We collect information to make your experience better, not because we're nosy. Here's what we gather:</p>
      <ul className="list-disc pl-6 mb-6">
        <li className="mb-2"><strong className="font-semibold">Personal Information:</strong> Your name, email, and other details when you sign up. Don't worry, we don't care about your middle name.</li>
        <li className="mb-2"><strong className="font-semibold">Usage Data:</strong> Like most websites, we collect data on how you use FlightFormula. Think of it as our way of learning your favorite inflight movie—only less creepy.</li>
        <li className="mb-2"><strong className="font-semibold">Cookies:</strong> No, not the chocolate chip kind (sadly). These are tiny files that help us remember your preferences. You can disable them, but things might get a bit bumpy.</li>
      </ul>

      <h2 className="text-2xl font-semibold text-purple-700 mt-8 mb-4">2. How We Use Your Information</h2>
      <p className="mb-4">We use your information to improve our services and make FlightFormula your go-to for travel planning. Specifically:</p>
      <ul className="list-disc pl-6 mb-6">
        <li className="mb-2">To enhance your user experience, like recommending better flights or reminding you about that sweet deal you almost booked.</li>
        <li className="mb-2">To send you updates, promotions, and flight deals. We promise not to spam—our emails are more like delightful postcards, not junk mail.</li>
        <li className="mb-2">For analytics, because we like to know what's working and what isn't. It's like feedback, but without the awkward face-to-face conversation.</li>
      </ul>

      <h2 className="text-2xl font-semibold text-purple-700 mt-8 mb-4">3. Sharing Your Information</h2>
      <p className="mb-4">We don't share your info with random strangers, but there are a few exceptions:</p>
      <ul className="list-disc pl-6 mb-6">
        <li className="mb-2"><strong className="font-semibold">With Service Providers:</strong> We might share data with trusted partners who help us operate FlightFormula, like hosting services or the friendly folks who keep our site running smoothly.</li>
        <li className="mb-2"><strong className="font-semibold">For Legal Reasons:</strong> If we're required by law, we'll share your info. But we promise we won't tattle on you unless we absolutely have to.</li>
        <li className="mb-2"><strong className="font-semibold">In Business Transfers:</strong> If FlightFormula ever merges, gets acquired, or otherwise evolves into something cooler, your data might be part of the package.</li>
      </ul>

      <h2 className="text-2xl font-semibold text-purple-700 mt-8 mb-4">4. Your Privacy Choices</h2>
      <p className="mb-4">You're in control! Here's what you can do:</p>
      <ul className="list-disc pl-6 mb-6">
        <li className="mb-2"><strong className="font-semibold">Access and Update:</strong> You can access and update your information any time by logging into your account. Think of it like upgrading your seat to business class.</li>
        <li className="mb-2"><strong className="font-semibold">Opt-Out:</strong> Don't want emails from us? You can opt-out. No hard feelings; just know you might miss out on some sweet deals.</li>
        <li className="mb-2"><strong className="font-semibold">Delete Your Account:</strong> You can delete your account at any time, but we'll miss you. It's like losing a passenger from the manifest.</li>
      </ul>

      <h2 className="text-2xl font-semibold text-purple-700 mt-8 mb-4">5. Security</h2>
      <p className="mb-4">We take security seriously—like TSA seriously. We use encryption and other safeguards to protect your data. But keep in mind, no method of transmission over the internet is 100% secure. So, don't share your super-secret flight hacks here.</p>

      <h2 className="text-2xl font-semibold text-purple-700 mt-8 mb-4">6. Children's Privacy</h2>
      <p className="mb-4">FlightFormula isn't intended for anyone under 13. If you're a kid reading this, go play outside! If you're a parent and think your kid has shared info with us, let us know, and we'll take care of it faster than a boarding call.</p>

      <h2 className="text-2xl font-semibold text-purple-700 mt-8 mb-4">7. Changes to This Privacy Policy</h2>
      <p className="mb-4">We might update this policy from time to time. If we do, we'll let you know in a way that's not buried in fine print. Think of it as an inflight announcement—you can't miss it.</p>

      <h2 className="text-2xl font-semibold text-purple-700 mt-8 mb-4">8. Contact Us</h2>
      <p className="mb-4">Questions? Concerns? Want to share your favorite travel tips? Contact us at privacy@flightformula.com. We're here to help, chat, or just listen to your travel woes.</p>

      <p className="mt-8 text-gray-700">By using FlightFormula, you agree to this Privacy Policy and consent to us handling your data as described. Now, go find your next adventure—worry-free!</p>
    </div>
  );
};

export default PrivacyPolicy;
