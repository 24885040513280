import React, { useState, useEffect, useRef } from 'react';
import { useUser } from "@clerk/clerk-react";
import { latestVideoURL } from './Home';
import WinePlusPage from './tools/WinePlusPage';
import LoadingSpinner from '../components/LoadingSpinner';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table";
import { Alert, AlertDescription, AlertTitle } from "../components/ui/alert";
import { Link } from 'react-router-dom';
import { Switch } from '../components/ui/switch';
import { Label } from '../components/ui/label';
import { Button } from "../components/ui/button";
import { Info } from 'lucide-react';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../components/ui/tooltip";

const QFWineTracker = () => {
    const { isLoaded: isUserLoaded, isSignedIn, user } = useUser();
    const [isWinePlus, setIsWinePlus] = useState(false);
    const [loading, setLoading] = useState(true);
    const [subLoading, setSubLoading] = useState(true);
    const [wineData, setWineData] = useState(null);
    const [bestDealsData, setBestDealsData] = useState(null);
    const [activeFigures, setActiveFigures] = useState({
        discountRate: 0,
        voucherAmount: 0,
    });
    const [lastUpdated, setLastUpdated] = useState(null);
    const [openTooltip, setOpenTooltip] = useState({
        pcp: false,
        voucher: false
    });
    const [expandedRow, setExpandedRow] = useState(null);
    const [videoDimensions, setVideoDimensions] = useState({ width: 0, height: 0 });
    const videoContainerRef = useRef(null);

    useEffect(() => {
        if (isUserLoaded) {
            if (isSignedIn && user) {
                const userSubscription = user.publicMetadata.subscription;
                setIsWinePlus(userSubscription === "WinePlus");
            } else {
                setIsWinePlus(false);
            }
            setSubLoading(false);
            fetchData(); // Move fetchData here to ensure it's called for all users
        }
    }, [isUserLoaded, isSignedIn, user]);

    const fetchData = async () => {
        try {
            setLoading(true);
            const [currentWineData, bestDealsData] = await Promise.all([
                fetch('https://data.flightformula.com/CurrentWine.json').then(response => response.json()),
                fetch('https://data.flightformula.com/BestDealsEver.json').then(response => response.json())
            ]);
            setWineData(currentWineData);
            setBestDealsData(bestDealsData);
            setLastUpdated(new Date(currentWineData.Timestamp * 1000));
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    const recalculateWineMetrics = (wines, discountRate, voucherAmount) => {
        return wines.map(wine => {
            let price = (parseFloat(wine.price.replace('$', '')) * (1 - discountRate)) - voucherAmount;
            if (price < 0) price = 0;

            const points = parseFloat(wine.points);
            const pointsPerDollar = price ? points / price : 0;
            const centsPerPoint = pointsPerDollar ? (price / points) * 100 : 0;

            return {
                ...wine,
                price: `$${price.toFixed(2)}`,
                pointsPerDollar: pointsPerDollar.toFixed(2),
                centsPerPoint: centsPerPoint.toFixed(2),
            };
        });
    };

    const getTimeSinceUpdate = () => {
        if (!lastUpdated) return '';
        const now = new Date();
        const diffInMinutes = Math.floor((now - lastUpdated) / (1000 * 60));
        return `${diffInMinutes} Minute${diffInMinutes !== 1 ? 's' : ''} Ago`;
    };

    function timeConverter(UNIX_timestamp){
        var a = new Date(UNIX_timestamp * 1000);
        var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var hour = a.getHours();
        var ampm = "AM"
        if (hour < 10) {
            hour = '0' + hour;
        }
        if (hour > 12) {
            hour = hour - 12;
            ampm = "PM"
        }
        if (hour === 12) {
            ampm = "PM"
        }
        if (hour === 0) {
            hour = 12;
        }
        
        var min = a.getMinutes();
        if (min < 10) {
            min = '0' + min;
        }

        var sec = a.getSeconds();
        if (sec < 10) {
            sec = '0' + sec;
        }
        var time =  hour + ':' + min + ampm + " | " + date + ' ' + month + ' ' + year;
        return time;
    }

    useEffect(() => {
        const updateVideoDimensions = () => {
            if (videoContainerRef.current) {
                const containerWidth = videoContainerRef.current.offsetWidth;
                setVideoDimensions({
                    width: containerWidth,
                    height: Math.floor(containerWidth * 9 / 16) // 16:9 aspect ratio
                });
            }
        };

        // Initial call
        updateVideoDimensions();

        // Set up resize listener
        window.addEventListener('resize', updateVideoDimensions);

        // Cleanup
        return () => window.removeEventListener('resize', updateVideoDimensions);
    }, []);

    if (loading || subLoading) {
        return <LoadingSpinner />;
    }

    if (isWinePlus && !subLoading) {
        return <WinePlusPage />;
    }

    const recalculatedProducts = recalculateWineMetrics(
        wineData?.Best10 || [],
        activeFigures.discountRate,
        activeFigures.voucherAmount
    );

    const ExpandedRowDetails = ({ wine }) => (
        <div className="p-4 bg-gray-100 text-sm">
            <p><strong>Style:</strong> {wine.wineStyle}</p>
            <p><strong>Points per Dollar:</strong> {wine.pointsPerDollar}</p>
            <p><strong>Green Tier:</strong> {wine.greenTier ? '✅' : '❌'}</p>
            <div className="flex justify-center mt-4">
                <Button 
                    className="w-full bg-purple-600 hover:bg-purple-700 text-white font-bold py-1 px-4 rounded-full"
                    onClick={() => window.open(wine.url, '_blank')}
                >
                    See Deal 
                </Button>
            </div>
        </div>
    );

    return (
        <div className="p-2 md:p-8 mx-auto max-w-full">
            <div className="flex flex-col md:flex-row justify-between items-start mb-4">
                <div className="w-full md:w-2/3">
                    <h1 className='text-4xl font-extrabold mb-0 md:mb-2'>
                        QF WineTracker🫡🍷
                    </h1>
                    <span className="text-sm font-bold block mb-2">
                        Last updated: {getTimeSinceUpdate()}
                    </span>
                </div>
            </div>

            <div className="mb-4 flex flex-row justify-between items-start">
                <div>
                    <h2 className="text-2xl font-bold">Current Deals</h2>
                    <p className="text-sm text-gray-500 mt-1 md:hidden">Tap row for more info</p>
                    <p className="text-sm text-gray-500 mt-1 hidden md:block">Click row to go to deal</p>
                </div>
                <div className="flex flex-col items-end space-y-2">
                    <div className="flex items-center space-x-2">
                        <Switch
                            id="pcp-discount"
                            checked={activeFigures.discountRate === 0.1}
                            onCheckedChange={(checked) => {
                                setActiveFigures(prev => ({ ...prev, discountRate: checked ? 0.1 : 0 }));
                            }}
                        />
                        <Label htmlFor="pcp-discount" className="text-sm">PC+ 10% Off</Label>
                        <TooltipProvider>
                            <Tooltip open={openTooltip.pcp} onOpenChange={(open) => setOpenTooltip(prev => ({ ...prev, pcp: open }))}>
                                <TooltipTrigger asChild>
                                    <Info 
                                        size={16} 
                                        className="text-gray-500 cursor-pointer" 
                                        onClick={() => setOpenTooltip(prev => ({ ...prev, pcp: !prev.pcp }))}
                                    />
                                </TooltipTrigger>
                                <TooltipContent>
                                    <p className="max-w-xs">
                                        Points Club Plus members get a 10% discount on wine purchases.
                                    </p>
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                    </div>
                    <div className="flex items-center space-x-2">
                        <Switch
                            id="voucher"
                            checked={activeFigures.voucherAmount === 50}
                            onCheckedChange={(checked) => {
                                setActiveFigures(prev => ({ ...prev, voucherAmount: checked ? 50 : 0 }));
                            }}
                        />
                        <Label htmlFor="voucher" className="text-sm">$50 Voucher</Label>
                        <TooltipProvider>
                            <Tooltip open={openTooltip.voucher} onOpenChange={(open) => setOpenTooltip(prev => ({ ...prev, voucher: open }))}>
                                <TooltipTrigger asChild>
                                    <Info 
                                        size={16} 
                                        className="text-gray-500 cursor-pointer" 
                                        onClick={() => setOpenTooltip(prev => ({ ...prev, voucher: !prev.voucher }))}
                                    />
                                </TooltipTrigger>
                                <TooltipContent>
                                    <p className="max-w-xs">
                                        Points Club members get a $50 voucher to use on their wine purchase. Apply it now to compare deals.
                                    </p>
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                    </div>
                </div>
            </div>

            {recalculatedProducts && (
                <div className="overflow-x-auto mb-8">
                    <Table>
                        <TableHeader>
                            <TableRow>
                                <TableHead>Brand</TableHead>
                                <TableHead className="hidden md:table-cell">Style</TableHead>
                                <TableHead>Points per Dollar</TableHead>
                                <TableHead>Cents per Point</TableHead>
                                <TableHead>Price</TableHead>
                                <TableHead>Points</TableHead>
                                <TableHead className="hidden md:table-cell text-center text-green-600">Green Tier</TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {recalculatedProducts.map((wine, index) => (
                                <React.Fragment key={wine.name}>
                                    <TableRow 
                                        className="hover:bg-gray-100 cursor-pointer" 
                                        onClick={() => {
                                            if (window.innerWidth < 768) {
                                                setExpandedRow(expandedRow === index ? null : index);
                                            } else {
                                                window.open(wine.url, '_blank');
                                            }
                                        }}
                                    >
                                        <TableCell className="font-medium whitespace-normal">{wine.name}</TableCell>
                                        <TableCell className="hidden md:table-cell">{wine.wineStyle}</TableCell>
                                        <TableCell >{wine.pointsPerDollar}</TableCell>
                                        <TableCell>{wine.centsPerPoint}</TableCell>
                                        <TableCell>{wine.price}</TableCell>
                                        <TableCell>{wine.points}</TableCell>
                                        <TableCell className="hidden md:table-cell text-center">{wine.greenTier ? '✅' : '❌'}</TableCell>
                                    </TableRow>
                                    {expandedRow === index && window.innerWidth < 768 && (
                                        <TableRow>
                                            <TableCell colSpan={7}>
                                                <ExpandedRowDetails wine={wine} />
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            )}
            <Alert className="mt-2 mb-8">
                <div className="flex flex-col items-center text-center">
                    <AlertTitle className="text-3xl font-bold mb-2">Subscribe to WineTracker<span className="text-purple-600">+</span></AlertTitle>
                    <AlertDescription className="mb-4">
                        Upgrade for Email + SMS alerts, every wine deal, advanced filtering (Reds+Whites) and more frequent updates!
                    </AlertDescription>
                    <Link to="/setup-account" className="w-full sm:w-auto">
                        <Button className="w-full bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-20 rounded-full text-base">
                            Learn More
                        </Button>
                    </Link>
                </div>
            </Alert>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                    <h2 className="text-2xl font-bold mb-4">Latest Video:</h2>
                    <div className="flex flex-col items-center">
                        <div ref={videoContainerRef} className="video-container w-full max-w-4xl mx-auto">
                            <div className="w-full" style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden' }}>
                                <iframe 
                                    src={latestVideoURL} 
                                    title="YouTube video player" 
                                    frameBorder="0" 
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                    allowFullScreen
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%'
                                    }}
                                ></iframe>
                            </div>
                        </div>
                        <Button 
                            className="w-full md:w-auto bg-purple-600 hover:bg-purple-700 text-white font-bold py-1 px-2 md:py-2 md:px-4 rounded-full mt-4"
                            onClick={() => window.open("https://www.youtube.com/@FlightFormula", "_blank")}
                        >
                            Check out my Youtube channel here!
                        </Button>
                    </div>
                </div>

                <div>
                <h3 className="text-2xl font-bold mb-4">Top 10 deals ever detected:</h3>
                    {bestDealsData && (
                        <div className="overflow-x-auto mb-6">
                            <Table>
                                <TableHeader>
                                    <TableRow>
                                        <TableHead>Brand</TableHead>
                                        <TableHead>Points per Dollar</TableHead>
                                        <TableHead>Cents per Point</TableHead>
                                        <TableHead>Price</TableHead>
                                        <TableHead>Last Seen</TableHead>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {bestDealsData.slice(0, 10).map((wine) => (
                                        <TableRow key={wine.name} className="hover:bg-gray-100 cursor-pointer" onClick={() => window.open(wine.url, '_blank')}>
                                            <TableCell className="font-medium whitespace-normal">{wine.name}</TableCell>
                                            <TableCell>{wine.pointsPerDollar}</TableCell>
                                            <TableCell>{(100/wine.pointsPerDollar).toFixed(2)}</TableCell>
                                            <TableCell>{`${wine.price.charAt(0) === '$' ? '' : '$'}${wine.price.includes('.') ? wine.price : `${wine.price}.00`}`}</TableCell>
                                            <TableCell>{timeConverter(wine.timestamp).split('|')[1]}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    )}
                </div>
            </div>
            <div className="mt-8">
                <h3 className="text-2xl font-medium mb-4">The best 10 deals <span className="font-extrabold italic">after</span> the $50 voucher is applied:</h3>
                {wineData?.Best10Discounted && (
                    <div className="overflow-x-auto mb-6">
                        <Table>
                            <TableHeader>
                                <TableRow>
                                    <TableHead>Brand</TableHead>
                                    <TableHead className="hidden md:table-cell">Style</TableHead>
                                    <TableHead>Points per Dollar</TableHead>
                                    <TableHead>Cents per Point</TableHead>
                                    <TableHead>Price (after voucher)</TableHead>
                                    <TableHead>Points</TableHead>
                                    <TableHead className="hidden md:table-cell text-center text-green-600">Green</TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {wineData.Best10Discounted.map((wine) => (
                                    <TableRow key={wine.name} className="hover:bg-gray-100 cursor-pointer" onClick={() => window.open(wine.url, '_blank')}>
                                        <TableCell className="font-medium whitespace-normal">{wine.name}</TableCell>
                                        <TableCell className="hidden md:table-cell">{wine.wineStyle}</TableCell>
                                        <TableCell>{wine.pointsPerDollar}</TableCell>
                                        <TableCell>{wine.centsPerPoint}</TableCell>
                                        <TableCell>{wine.price}</TableCell>
                                        <TableCell>{wine.points}</TableCell>
                                        <TableCell className="hidden md:table-cell text-center">{wine.greenTier ? '✅' : '❌'}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                )}
            </div>
        </div>
    );
};

export default QFWineTracker;