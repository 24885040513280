import React, { useState, useEffect, useRef } from 'react';
import { useUser } from "@clerk/clerk-react";
import { latestVideoURL } from '../Home';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import { Card, CardHeader, CardTitle, CardContent } from "../../components/ui/card";
import { Label } from "../../components/ui/label";
import { Switch } from "../../components/ui/switch";
import { Button } from "../../components/ui/button";
import { Checkbox } from "../../components/ui/checkbox";
import { Info } from 'lucide-react';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../../components/ui/tooltip";
import LoadingSpinner from '../../components/LoadingSpinner';

const WinePlusPage = () => {
    const { user, isLoaded } = useUser();
    const [premiumContent, setPremiumContent] = useState(null);
    const [error, setError] = useState(null);
    const [showAllProducts, setShowAllProducts] = useState(false);
    const [filters, setFilters] = useState({
        wineStyles: [],
    });
    const [activeFigures, setActiveFigures] = useState({
        discountRate: 0,
        voucherAmount: 0,
    });
    const [openWineStyles, setOpenWineStyles] = useState(false);
    const [showRedWines, setShowRedWines] = useState(false);
    const [showWhiteWines, setShowWhiteWines] = useState(false);
    const wineStylesRef = useRef(null);
    const videoSizeFactor = getVideoSizeFactor();    
    const [videoWidth, setVideoWidth] = useState(window.innerWidth/videoSizeFactor);
    const [videoHeight, setVideoHeight] = useState(videoWidth * 0.5625);
    const [lastUpdated, setLastUpdated] = useState(null);
    const [showGreenTierOnly, setShowGreenTierOnly] = useState(false);
    const [showBetterThanBuyingPoints, setShowBetterThanBuyingPoints] = useState(false);
    const [openTooltip, setOpenTooltip] = useState(false);
    const [expandedRow, setExpandedRow] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const ExpandedRowDetails = ({ wine }) => (
        <div className="p-4 bg-gray-100 text-sm">
          <p><strong>Style:</strong> {wine.wineStyle}</p>
          <p><strong>Points per Dollar:</strong> {wine.pointsPerDollar}</p>
          <p><strong>Bottles:</strong> {wine.numberOfBottles}</p>
          <p><strong>Price per Bottle:</strong> ${(parseFloat(wine.price.replace('$', '')) / parseInt(wine.numberOfBottles)).toFixed(2)}</p>
          <p><strong>Green Tier:</strong> {wine.greenTier ? '✅' : '❌'}</p>
        <div className="flex justify-center mt-4">
            <Button 
                className="w-full bg-purple-600 hover:bg-purple-700 text-white font-bold py-1 px-4 rounded-full"
                onClick={() => window.open(wine.url, '_blank')}
            >
                See Deal 
            </Button>
        </div>
        </div>
      );

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 768) {
            setExpandedRow(null);
            }
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);

    useEffect(() => {
        if (isLoaded && user) {
            fetchPremiumContent();
        }

        // Add event listener for closing the dropdown
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isLoaded, user]);

    const handleClickOutside = (event) => {
        if (wineStylesRef.current && !wineStylesRef.current.contains(event.target)) {
            setOpenWineStyles(false);
        }
    };

    function getVideoSizeFactor() {
        if (window.innerWidth < 768) {
            return 1.5;
        }
        else {
            return 3;
        }

    }
    const fetchPremiumContent = async () => {
        try {
            setIsLoading(true);
            const currentWinePremiumURL = user.publicMetadata.currentWinePremiumURL;
            const currentBestDealsEverPremiumURL = user.publicMetadata.currentBestDealsEverPremiumURL;

            if (!currentWinePremiumURL || !currentBestDealsEverPremiumURL) {
                throw new Error('Premium content source not found');
            }

            const [wineResponse, bestDealsResponse] = await Promise.all([
                fetch(currentWinePremiumURL),
                fetch(currentBestDealsEverPremiumURL)
            ]);

            if (!wineResponse.ok || !bestDealsResponse.ok) {
                throw new Error('Failed to fetch premium content');
            }

            const wineData = await wineResponse.json();
            const bestDealsData = await bestDealsResponse.json();

            setPremiumContent({
                ...wineData,
                bestDealsEver: bestDealsData
            });
            setLastUpdated(new Date(wineData.Timestamp * 1000));
        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleFilterChange = (key, value) => {
        if (key === 'wineStyles') {
            setFilters(prev => ({
                ...prev,
                wineStyles: prev.wineStyles.includes(value)
                    ? prev.wineStyles.filter(style => style !== value)
                    : [...prev.wineStyles, value]
            }));
        } else {
            setFilters(prev => ({ ...prev, [key]: value }));
        }
    };

    const applyFilters = (products) => {
        return products.filter(product => {
            const isBetterThanBuyingPoints = parseFloat(product.centsPerPoint) <= 2.666;
            return (
                (filters.wineStyles.length === 0 || filters.wineStyles.includes(product.wineStyle)) &&
                (!showGreenTierOnly || product.greenTier) &&
                (!showBetterThanBuyingPoints || isBetterThanBuyingPoints)
            );
        });
    };

    const recalculateWineMetrics = (products, discountRate, voucherAmount) => {
        return products.map(wine => {
            let price = (parseFloat(wine.price) * (1 - discountRate)) - voucherAmount;
            if (price < 0) price = 0;

            const points = parseFloat(wine.points);
            const pointsPerDollar = price ? points / price : 0;
            const centsPerPoint = pointsPerDollar ? (price / points) * 100 : 0;

            return {
                ...wine,
                price: `$${price.toFixed(2)}`,
                pointsPerDollar: pointsPerDollar.toFixed(2),
                centsPerPoint: centsPerPoint.toFixed(2),
            };
        });
    };

    const handleRedWhiteToggle = (isRed) => {
        const redStyles = [
            "Red - juicy, smooth, medium-bodied",
            "Red - big, bold, full-bodied",
            "Red - bright, silky, light-bodied",
            "Mixed - variety of red wine styles"
        ];
        const whiteStyles = [
            "White - rich, mouth filling, full-bodied",
            "White - crisp, thirst quenching, light-bodied",
            "White - juicy, refreshing, light to medium-bodied",
            "White - fruit sweetness, well balanced, medium-bodied",
            "Mixed - variety of white wine styles"
        ];

        if (isRed) {
            setShowRedWines(!showRedWines);
            setFilters(prev => ({
                ...prev,
                wineStyles: showRedWines ? prev.wineStyles.filter(style => !redStyles.includes(style)) : [...new Set([...prev.wineStyles, ...redStyles])]
            }));
        } else {
            setShowWhiteWines(!showWhiteWines);
            setFilters(prev => ({
                ...prev,
                wineStyles: showWhiteWines ? prev.wineStyles.filter(style => !whiteStyles.includes(style)) : [...new Set([...prev.wineStyles, ...whiteStyles])]
            }));
        }
    };

    useEffect(() => {
        const handleResize = () => {
            const newWidth = window.innerWidth/2.5;
            setVideoWidth(newWidth);
            setVideoHeight(newWidth * 0.5625);
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    const getTimeSinceUpdate = () => {
        if (!lastUpdated) return '';
        const now = new Date();
        const diffInMinutes = Math.floor((now - lastUpdated) / (1000 * 60));
        return `${diffInMinutes} Minute${diffInMinutes !== 1 ? 's' : ''} Ago`;
    };

    if (!isLoaded || isLoading) {
        return <LoadingSpinner />;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    const filteredProducts = applyFilters(premiumContent?.AllProducts || []);
    const recalculatedProducts = recalculateWineMetrics(
        filteredProducts,
        activeFigures.discountRate,
        activeFigures.voucherAmount
    );
    function timeConverter(UNIX_timestamp){
        var a = new Date(UNIX_timestamp * 1000);
        var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var hour = a.getHours();
        var ampm = "AM"
        if (hour < 10) {
            hour = '0' + hour;
        }
        if (hour > 12) {
            hour = hour - 12;
            ampm = "PM"
        }
        if (hour === 12) {
            ampm = "PM"
        }
        if (hour === 0) {
            hour = 12;
        }
        
        var min = a.getMinutes();
        if (min < 10) {
            min = '0' + min;
        }

        var sec = a.getSeconds();
        if (sec < 10) {
            sec = '0' + sec;
        }
        var time =  hour + ':' + min + ampm + " | " + date + ' ' + month + ' ' + year;
        return time;
      }
    

    const displayedProducts = showAllProducts ? recalculatedProducts : recalculatedProducts.slice(0, 10);

    return (
        <div className="p-2 md:p-8 mx-auto">
            <div className="mb-2 md:mb-8 flex flex-col md:flex-row justify-between items-start">
                <div className="w-full md:w-2/3">
                    <h1 className='text-4xl font-extrabold mb-0 md:mb-2'>
                        QF WineTracker<span className="text-purple-600">+</span> 🫡🍷
                    </h1>
                    <span className="text-sm font-bold block mb-2">
                        Last updated: {getTimeSinceUpdate()}
                    </span>
                    <div className="hidden md:block">
                        <p className="text-sm md:text-base ">
                            Reds: {premiumContent ? premiumContent.AllProducts.filter(wine => wine.wineStyle.includes('Red')).length : 0} | 
                            Whites: {premiumContent ? premiumContent.AllProducts.filter(wine => wine.wineStyle.includes('White')).length : 0} | 
                            Other: {premiumContent ? premiumContent.AllProducts.filter(wine => !wine.wineStyle.includes('Red') && !wine.wineStyle.includes('White')).length : 0}
                        </p>
                        <p className="text-sm font-semibold md:text-base">
                            Total Wine Count: {premiumContent ? premiumContent.AllProducts.length : 0}
                        </p>
                    </div>
                    <div className="mt-4 md:mt-8">
                        <p className="text-base font-semibold md:text-xl">
                            {user.firstName ? `${user.firstName}, ` : ''}thank you for being a WinePlus subscriber!
                        </p>
                    </div>
                </div>

                <Card className="w-full md:w-1/3 mt-4 md:mt-0 md:ml-4 mb-4 md:mb-0">
                    <CardHeader className="py-2">
                        <CardTitle className="text-lg md:text-xl">Filters</CardTitle>
                    </CardHeader>
                    <CardContent className="py-2">
                        <div className="space-y-2">
                            <div ref={wineStylesRef} className="w-full">
                                <Label htmlFor="wineStyles" className="mb-2 block text-sm">Wine Styles</Label>
                                <div className="relative border-2 border-gray-300 rounded-md">
                                    <Button
                                        type="button"
                                        onClick={() => setOpenWineStyles(!openWineStyles)}
                                        className="w-full justify-between bg-transparent text-gray-800 hover:bg-gray-100"
                                    >
                                        {filters.wineStyles.length > 0
                                            ? `${filters.wineStyles.length} selected`
                                            : "Select Wine Styles"}
                                        <span className="ml-2">▼</span>
                                    </Button>
                                    {openWineStyles && (
                                        <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto">
                                            {premiumContent?.WineStyles.map((style) => (
                                                <div
                                                    key={style}
                                                    className="flex items-center p-2 hover:bg-gray-100"
                                                >
                                                    <Checkbox
                                                        id={style}
                                                        checked={filters.wineStyles.includes(style)}
                                                        onCheckedChange={(checked) => {
                                                            handleFilterChange('wineStyles', style);
                                                        }}
                                                    />
                                                    <label
                                                        htmlFor={style}
                                                        className="ml-2 text-sm cursor-pointer"
                                                    >
                                                        {style}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 gap-2 md:gap-4 mb-2">
                                <div className="flex items-center space-x-2">
                                    <Switch
                                        id="pcp-discount"
                                        checked={activeFigures.discountRate === 0.1}
                                        onCheckedChange={(checked) => {
                                            setActiveFigures(prev => ({ ...prev, discountRate: checked ? 0.1 : 0 }));
                                        }}
                                    />
                                    <Label htmlFor="pcp-discount" className="text-sm">10% PCP</Label>
                                </div>
                                <div className="flex items-center space-x-1">
                                    <Switch
                                        id="voucher"
                                        checked={activeFigures.voucherAmount === 50}
                                        onCheckedChange={(checked) => {
                                            setActiveFigures(prev => ({ ...prev, voucherAmount: checked ? 50 : 0 }));
                                        }}
                                    />
                                    <Label htmlFor="voucher" className="text-sm">$50 Voucher</Label>
                                </div>
                                <div className="flex items-center space-x-1">
                                    <Switch
                                        id="red-wines"
                                        checked={showRedWines}
                                        onCheckedChange={() => handleRedWhiteToggle(true)}
                                    />
                                    <Label htmlFor="red-wines" className="text-sm">Red Wines</Label>
                                </div>
                                <div className="flex items-center space-x-1">
                                    <Switch
                                        id="white-wines"
                                        checked={showWhiteWines}
                                        onCheckedChange={() => handleRedWhiteToggle(false)}
                                    />
                                    <Label htmlFor="white-wines" className="text-sm">White Wines</Label>
                                </div>
                                <div className="flex items-center space-x-2">
                                    <Switch
                                        id="green-tier"
                                        checked={showGreenTierOnly}
                                        onCheckedChange={setShowGreenTierOnly}
                                    />
                                    <Label htmlFor="green-tier" className="text-sm">Green Tier</Label>
                                </div>
                                <div className="flex items-center space-x-2">
                                    <Switch
                                        id="better-value"
                                        checked={showBetterThanBuyingPoints}
                                        onCheckedChange={setShowBetterThanBuyingPoints}
                                    />
                                    <Label htmlFor="better-value" className="text-sm">Better Value</Label>
                                    <TooltipProvider>
                                        <Tooltip open={openTooltip} onOpenChange={setOpenTooltip}>
                                            <TooltipTrigger asChild>
                                                <Info 
                                                    size={16} 
                                                    className="text-gray-500 cursor-pointer" 
                                                    onClick={() => setOpenTooltip(!openTooltip)}
                                                />
                                            </TooltipTrigger>
                                            <TooltipContent>
                                                <p className="max-w-xs">
                                                    This filters out wines that are not better value than buying points directly from Qantas. 
                                                    See <a href="https://flightformula.com/posts/qantas-top-up-points-bad-value-but-how-bad" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">here</a> for more information on buying points.
                                                </p>
                                            </TooltipContent>
                                        </Tooltip>
                                    </TooltipProvider>
                                </div>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </div>

            <div className="mb-0 md:mb-2 flex flex-col sm:flex-row justify-between items-start sm:items-center">
                <div className="flex justify-between items-center w-full sm:w-auto">
                    <h2 className="text-2xl font-bold">Current Deals</h2>
                    <div className="flex items-center space-x-2 sm:hidden">
                        <Label htmlFor="show-all-deals" className="text-sm">Show All</Label>
                        <Switch
                            id="show-all-deals"
                            checked={showAllProducts}
                            onCheckedChange={setShowAllProducts}
                        />
                    </div>
                </div>
                <p className="text-xs text-gray-500 sm:hidden ml-1">Tap row for more info</p>
                <p className="text-xs text-gray-500 hidden md:block ml-1">Click row to go to deal</p>
                <div className="hidden sm:flex items-center space-x-2 mt-2 sm:mt-0">
                    <Label htmlFor="show-all-deals">Show All Deals</Label>
                    <Switch
                        id="show-all-deals"
                        checked={showAllProducts}
                        onCheckedChange={setShowAllProducts}
                    />
                </div>
            </div>

            {displayedProducts && (
                <div className="overflow-x-auto mb-8">
                    <Table>
                        <TableHeader>
                            <TableRow>
                                <TableHead>Brand</TableHead>
                                <TableHead className="hidden md:table-cell">Style</TableHead>
                                <TableHead>Points per Dollar</TableHead>
                                <TableHead>Cents per Point</TableHead>
                                <TableHead>Price</TableHead>
                                <TableHead>Points</TableHead>
                                <TableHead className="hidden md:table-cell">Bottles</TableHead>
                                <TableHead className="hidden md:table-cell">$/Bottle</TableHead>
                                <TableHead className="hidden md:table-cell text-center text-green-600">Green Tier</TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                        {displayedProducts.map((wine, index) => (
                            <React.Fragment key={wine.name}>
                                <TableRow 
                                className="hover:bg-gray-100 cursor-pointer" 
                                onClick={() => {
                                    if (window.innerWidth < 768) {
                                    setExpandedRow(expandedRow === index ? null : index);
                                    } else {
                                    window.open(wine.url, '_blank');
                                    }
                                }}
                                >
                                <TableCell className="font-medium whitespace-normal">{wine.name}</TableCell>
                                <TableCell className="hidden md:table-cell">{wine.wineStyle}</TableCell>
                                <TableCell>{wine.pointsPerDollar}</TableCell>
                                <TableCell>{wine.centsPerPoint} Cents</TableCell>
                                <TableCell>{wine.price}</TableCell>
                                <TableCell>{wine.points}</TableCell>
                                <TableCell className="hidden md:table-cell">{wine.numberOfBottles}</TableCell>
                                <TableCell className="hidden md:table-cell">${(parseFloat(wine.price.replace('$', '')) / parseInt(wine.numberOfBottles)).toFixed(2)}</TableCell>
                                <TableCell className="hidden md:table-cell text-center">{wine.greenTier ? '✅' : '❌'}</TableCell>
                                </TableRow>
                                {expandedRow === index && window.innerWidth < 768 && (
                                <TableRow>
                                    <TableCell colSpan={9}>
                                    <ExpandedRowDetails wine={wine} />
                                    </TableCell>
                                </TableRow>
                                )}
                            </React.Fragment>
                        ))}
                        </TableBody>
                    </Table>
                </div>
            )}

            <hr className="my-8 border-t border-gray-300" />

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                    <h2 className="text-2xl font-bold mb-4">Top 10 Deals Ever</h2>
                    {premiumContent?.bestDealsEver && (
                        <div className="overflow-x-auto mb-6">
                            <Table>
                                <TableHeader>
                                    <TableRow>
                                        <TableHead>Brand</TableHead>
                                        <TableHead>Points per Dollar</TableHead>
                                        <TableHead>Cents per Point</TableHead>
                                        <TableHead>Price</TableHead>
                                        <TableHead>Last Seen</TableHead>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {premiumContent.bestDealsEver.slice(0, 10).map((wine) => (
                                        <TableRow key={wine.name} className="hover:bg-gray-100 cursor-pointer" onClick={() => window.open(wine.url, '_blank')}>
                                            <TableCell className="font-medium whitespace-normal">{wine.name}</TableCell>
                                            <TableCell>{wine.pointsPerDollar}</TableCell>
                                            <TableCell>{(100/wine.pointsPerDollar).toFixed(2)} Cents</TableCell>
                                            <TableCell>{`${wine.price.charAt(0) === '$' ? '' : '$'}${wine.price.includes('.') ? wine.price : `${wine.price}.00`}`}</TableCell>
                                            <TableCell>{timeConverter(wine.timestamp).split('|')[1]}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    )}
                </div>

                <div>
                    <h2 className="text-2xl font-bold mb-4">Latest Video</h2>
                    <div className="flex flex-col items-center">
                        <div>
                            <iframe width={videoWidth} height={videoHeight} src={latestVideoURL} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                        <button className="w-full md:w-auto bg-purple-600 hover:bg-purple-700 text-white font-bold py-1 px-2 md:py-2 md:px-4 rounded-full mt-4" onClick={() => window.open("https://www.youtube.com/@FlightFormula", "_blank")}>Please check out my Youtube channel here!</button>
                    </div>
                </div>
            </div>

            <hr className="my-8 border-t border-gray-300" />

            <div className="mt-6">
                <h2 className="text-2xl font-bold mb-4">Best Deals After $50 Voucher</h2>
                {premiumContent?.Best10Discounted && (
                    <div className="overflow-x-auto mb-6">
                        <Table>
                            <TableHeader>
                                <TableRow>
                                    <TableHead>Brand</TableHead>
                                    <TableHead>Points per Dollar</TableHead>
                                    <TableHead>Cents per Point</TableHead>
                                    <TableHead>Price (after voucher)</TableHead>
                                    <TableHead className="hidden md:table-cell">Points</TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {premiumContent.Best10Discounted.map((wine) => (
                                    <TableRow key={wine.name} className="hover:bg-gray-100 cursor-pointer" onClick={() => window.open(wine.url, '_blank')}>
                                        <TableCell className="font-medium whitespace-normal">{wine.name}</TableCell>
                                        <TableCell>{wine.pointsPerDollar}</TableCell>
                                        <TableCell>{wine.centsPerPoint} Cents</TableCell>
                                        <TableCell>{`${wine.price.charAt(0) === '$' ? '' : '$'}${wine.price.includes('.') ? wine.price : `${wine.price}.00`}`}</TableCell>
                                        <TableCell className="hidden md:table-cell">{wine.points}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                )}
            </div>
        </div>
    );
};

export default WinePlusPage;
