import React, { useEffect, useState } from 'react';
import { useUser } from '@clerk/clerk-react';
import { Navigate } from 'react-router-dom';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, Tooltip } from 'recharts';

const StatCard = ({ title, total, breakdown }) => {
    const [expanded, setExpanded] = useState(false);

    return (
        <div 
            className="bg-white p-6 rounded-lg shadow-md cursor-pointer transition-all duration-300 hover:shadow-lg border border-gray-200"
            onClick={() => setExpanded(!expanded)}
        >
            <h2 className="text-lg font-semibold mb-2 text-gray-700">{title}</h2>
            <p className="text-3xl font-bold text-gray-800">{total}</p>
            {expanded && (
                <div className="mt-4 text-sm text-gray-600">
                    {Object.entries(breakdown).map(([key, value]) => (
                        <p key={key} className="flex justify-between items-center mt-2">
                            <span>{key}:</span>
                            <span className="font-semibold">{value}</span>
                        </p>
                    ))}
                </div>
            )}
        </div>
    );
};

const formatRelativeTime = (timestamp) => {
    const now = Date.now();
    const diff = now - timestamp * 1000;
    const minutes = Math.floor(diff / 60000);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) return `${days} day${days > 1 ? 's' : ''} ago`;
    if (hours > 0) return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    if (minutes > 0) return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    return 'Just now';
};

const ErrorLogRow = ({ log, hideErrorMessage = false, hideDetails = false }) => {
    const [expanded, setExpanded] = useState(false);

    const getDisplayErrorMessage = (errorMessage) => {
        if (errorMessage.includes('<TITLE>Access Denied</TITLE>')) {
            return "Tracker failed at Qantas API";
        }
        return errorMessage;
    };

    const formatOperation = (operation) => {
        return operation.split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    };

    return (
        <>
            <tr 
                className="cursor-pointer" 
                onClick={() => setExpanded(!expanded)}
            >
                <td className="border px-4 py-2">{formatOperation(log.operation)}</td>
                <td className="border px-4 py-2">{formatRelativeTime(log.unixTimestamp)}</td>
                <td className="border px-4 py-2 hidden md:table-cell">{log.userId}</td>
                {!hideDetails && (
                    <td className="border px-4 py-2 hidden md:table-cell">{log.details}</td>
                )}
                {!hideErrorMessage && (
                    <td className="border px-4 py-2">{getDisplayErrorMessage(log.errorMessage)}</td>
                )}
            </tr>
            {expanded && (
                <tr>
                    <td colSpan="5" className="border px-4 py-2 bg-gray-50">
                        <p><strong>User ID:</strong> {log.userId}</p>
                        {hideDetails && <p><strong>Details:</strong> {log.details}</p>}
                        {hideErrorMessage && <p><strong>Error Message:</strong> {getDisplayErrorMessage(log.errorMessage)}</p>}
                    </td>
                </tr>
            )}
        </>
    );
};

const ErrorLogTable = ({ logs, title, hideErrorMessage = false, hideDetails = false }) => (
    <div className="bg-white p-6 rounded-lg shadow-md mb-8">
        <h2 className="text-xl font-semibold mb-4 text-gray-700">{title}</h2>
        <div className="overflow-x-auto">
            <table className="min-w-full table-auto">
                <thead>
                    <tr className="bg-gray-200">
                        <th className="px-4 py-2">Operation</th>
                        <th className="px-4 py-2">Time</th>
                        <th className="px-4 py-2 hidden md:table-cell">User ID</th>
                        {!hideDetails && (
                            <th className="px-4 py-2 hidden md:table-cell">Details</th>
                        )}
                        {!hideErrorMessage && (
                            <th className="px-4 py-2">Error Message</th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {logs.map((log, index) => (
                        <ErrorLogRow 
                            key={index} 
                            log={log} 
                            hideErrorMessage={hideErrorMessage} 
                            hideDetails={hideDetails}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    </div>
);

const TinyLineChart = ({ data, dataKey, color }) => (
    <ResponsiveContainer width="100%" height={100}>
        <LineChart data={data}>
            <XAxis dataKey="timestamp" hide={true} />
            <YAxis hide={true} />
            <Tooltip
                labelFormatter={(unixTime) => new Date(unixTime * 1000).toLocaleString()}
                formatter={(value) => [value, dataKey]}
            />
            <Line type="monotone" dataKey={dataKey} stroke={color} strokeWidth={2} dot={false} />
        </LineChart>
    </ResponsiveContainer>
);

const Admin = () => {
    const { user, isLoaded, isSignedIn } = useUser();
    const [isGod, setIsGod] = useState(false);
    const [loading, setLoading] = useState(true);
    const [stats, setStats] = useState(null);
    const [wineTrackerStats, setWineTrackerStats] = useState([]);
    const [errorLogs, setErrorLogs] = useState({ wineTrackerErrors: [], userOperationErrors: [] });
    const [smsCredits, setSmsCredits] = useState(null);

    useEffect(() => {
        console.log('isLoaded changed:', isLoaded);
        if (isLoaded) {
            console.log('User loaded. isSignedIn:', isSignedIn, 'user:', user);
            if (isSignedIn && user) {
                checkGodStatusAndFetchData();
            } else {
                setLoading(false);
            }
        }
    }, [isLoaded, isSignedIn, user]);

    const checkGodStatusAndFetchData = async () => {
        try {
            console.log('Attempting to check god status...');
            const godCheckResponse = await fetch('https://ff-users.flightformula.workers.dev/god-check', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userId: user.id }),
            });

            console.log('God check response:', godCheckResponse);

            if (!godCheckResponse.ok) {
                throw new Error(`Failed to check god status: ${godCheckResponse.status} ${godCheckResponse.statusText}`);
            }

            const { isGod } = await godCheckResponse.json();
            setIsGod(isGod);
            setLoading(false);

            if (isGod) {
                fetchStats();
                fetchWineTrackerStats();
                fetchErrorLogs();
                fetchSmsCredits();
            }
        } catch (error) {
            console.error('Error in checkGodStatusAndFetchData:', error);
            setLoading(false);
        }
    };

    const fetchStats = async () => {
        try {
            const statsResponse = await fetch('https://ff-users.flightformula.workers.dev/admin-current-stats', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userId: user.id }),
            });
            if (!statsResponse.ok) {
                throw new Error('Failed to fetch stats data');
            }
            const statsData = await statsResponse.json();
            setStats(statsData);
        } catch (error) {
            console.error('Error fetching stats:', error);
        }
    };

    const fetchWineTrackerStats = async () => {
        try {
            const wineTrackerResponse = await fetch('https://ff-users.flightformula.workers.dev/admin-wine-tracker-stats', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userId: user.id }),
            });
            if (!wineTrackerResponse.ok) {
                throw new Error('Failed to fetch wine tracker stats');
            }
            const wineTrackerData = await wineTrackerResponse.json();
            setWineTrackerStats(wineTrackerData);
        } catch (error) {
            console.error('Error fetching wine tracker stats:', error);
        }
    };

    const fetchErrorLogs = async () => {
        try {
            const errorLogsResponse = await fetch('https://ff-users.flightformula.workers.dev/admin-error-logs', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userId: user.id }),
            });
            if (!errorLogsResponse.ok) {
                throw new Error('Failed to fetch error logs');
            }
            const errorLogsData = await errorLogsResponse.json();
            setErrorLogs(errorLogsData);
        } catch (error) {
            console.error('Error fetching error logs:', error);
        }
    };

    const fetchSmsCredits = async () => {
        try {
            const smsCreditsResponse = await fetch('https://ff-users.flightformula.workers.dev/admin-sms-credits', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userId: user.id }),
            });
            if (!smsCreditsResponse.ok) {
                throw new Error('Failed to fetch SMS credits');
            }
            const smsCreditsData = await smsCreditsResponse.json();
            setSmsCredits(smsCreditsData);
        } catch (error) {
            console.error('Error fetching SMS credits:', error);
        }
    };

    if (!isLoaded || loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
            </div>
        );
    }

    if (!isSignedIn) {
        return <Navigate to="/login" replace />;
    }

    if (!isGod) {
        return <Navigate to="/" replace />;
    }

    return (
        <div className="bg-gray-100 min-h-screen">
            <div className="container mx-auto p-6 max-w-8xl">
                <h1 className="text-3xl font-bold mb-8 text-left text-gray-800">Admin Dashboard</h1>
                
                {wineTrackerStats.length > 0 && (
                    <div className="text-base mb-8 text-left text-gray-600">
                        <p>
                            Last Update Was: <br/> <span className="font-semibold">{Math.floor((Date.now() / 1000 - wineTrackerStats[wineTrackerStats.length - 1].timestamp) / 60)} minute{Math.floor((Date.now() / 1000 - wineTrackerStats[wineTrackerStats.length - 1].timestamp) / 60) > 1 ? 's' : ''} ago</span>
                            <br />
                            {wineTrackerStats[wineTrackerStats.length - 1].allProductsLength} products found.
                            <br />
                            {wineTrackerStats[wineTrackerStats.length - 1].historicalDataLength} wines in the historical data.
                        </p>
                    </div>
                )}
                
                {stats ? (
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
                        <StatCard 
                            title="Users" 
                            total={stats.totalUsers}
                            breakdown={{ "WinePlus": stats.winePlusUsers, "Free": stats.totalUsers - stats.winePlusUsers }}
                        />
                        <StatCard 
                            title="Notifications (24h)" 
                            total={stats.testEmails + stats.realEmails + stats.testSms + stats.realSms}
                            breakdown={{ 
                                "Test Emails": stats.testEmails, 
                                "Real Emails": stats.realEmails,
                                "Test SMS": stats.testSms,
                                "Real SMS": stats.realSms
                            }}
                        />
                        {smsCredits && (
                            <StatCard 
                                title="SMS Credits" 
                                total={smsCredits.smsCredits.toFixed(2)}
                                breakdown={{ 
                                    "SMS Credits": smsCredits.smsCredits.toFixed(2),
                                    "MMS Credits": smsCredits.mmsCredits.toFixed(2)
                                }}
                            />
                        )}
                    </div>
                ) : (
                    <div className="text-center text-gray-600">Loading stats...</div>
                )}

                {wineTrackerStats.length > 0 ? (
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
                        <div className="bg-white p-6 rounded-lg shadow-md">
                            <h2 className="text-xl font-semibold mb-4 text-gray-700">All Products (Last 7 Days)</h2>
                            <TinyLineChart data={wineTrackerStats} dataKey="allProductsLength" color="#8884d8" />
                        </div>
                        <div className="bg-white p-6 rounded-lg shadow-md">
                            <h2 className="text-xl font-semibold mb-4 text-gray-700">Historical Data (Last 7 Days)</h2>
                            <TinyLineChart data={wineTrackerStats} dataKey="historicalDataLength" color="#82ca9d" />
                        </div>
                    </div>
                ) : (
                    <div className="text-center text-gray-600">Loading wine tracker stats...</div>
                )}

                {errorLogs.wineTrackerErrors.length > 0 ? (
                    <ErrorLogTable 
                        logs={errorLogs.wineTrackerErrors} 
                        title="Wine Tracker Error Logs" 
                        hideDetails={true}
                        hideErrorMessage={false}
                    />
                ) : (
                    <div className="text-center text-gray-600">Loading wine tracker error logs...</div>
                )}

                {errorLogs.userOperationErrors.length > 0 ? (
                    <ErrorLogTable 
                        logs={errorLogs.userOperationErrors} 
                        title="User Operation Error Logs" 
                        hideErrorMessage={true}
                        hideDetails={false}
                    />
                ) : (
                    <div className="text-center text-gray-600">Loading user operation error logs...</div>
                )}
            </div>
        </div>
    );
};

export default Admin;
