import React, { useState } from 'react';
import { useUser } from '@clerk/clerk-react';

const OpenInNewTabIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 inline-block ml-1">
    <path d="M10 6v2H5v11h11v-5h2v6a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h6zm11-3v8h-2V6.413l-7.793 7.794-1.414-1.414L17.585 5H13V3h8z" />
  </svg>
);

export const BillingPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useUser();

  const isSubscribed = user.publicMetadata.subscriptionStatus === 'active';

  const handleBillingPortal = async () => {
    setIsLoading(true);
    try {
      const response = await fetch('https://ff-users.flightformula.workers.dev/get-stripe-billing-portal', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId: user.id }),
      });

      if (!response.ok) {
        throw new Error('Failed to get billing portal URL');
      }

      const data = await response.json();
      window.open(data.url, '_blank');
    } catch (error) {
      console.error('Error accessing billing portal:', error);
      alert('Failed to access billing portal. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubscribe = () => {
    const baseUrl = process.env.REACT_APP_STRIPE_BASE_URL; // Get the base URL from environment variables
    const stripePaymentLink = `${baseUrl}?prefilled_email=${user.publicMetadata.email}&client_reference_id=${user.id}`;
    window.open(stripePaymentLink, '_blank');
  };

  return (
    <div className="max-w-2xl mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Billing</h1>
      <hr className="my-4" />
      
      {isSubscribed ? (
        <>
          <div className="mb-4">
            <p className="text-sm text-gray-600">
              Current period ends: <span className="font-semibold">
                {user.publicMetadata.subscriptionEndDate 
                  ? new Date(user.publicMetadata.subscriptionEndDate * 1000).toLocaleDateString() 
                  : 'N/A'}
              </span>
            </p>
            <p className="text-sm text-gray-600">
              Auto-renew: <span className="font-semibold">Yes</span>
            </p>
          </div>
          
          <p className="mb-4 text-sm text-gray-600">
            To manage your subscription, visit the Stripe billing portal below.
          </p>
          <button 
            className={`bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded inline-flex items-center ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
            onClick={handleBillingPortal}
            disabled={isLoading}
          >
            {isLoading ? 'Loading...' : (
              <>
                Manage your subscription
                <OpenInNewTabIcon />
              </>
            )}
          </button>
        </>
      ) : (
        <>
          <p className="mb-4 text-sm text-gray-600">
            You are not currently subscribed. Click the button below to subscribe.
          </p>
          <button 
            className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded inline-flex items-center"
            onClick={handleSubscribe}
          >
            Subscribe now
            <OpenInNewTabIcon />
          </button>
        </>
      )}
    </div>
  );
};