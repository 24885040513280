import React from 'react';

const Posts = () => {

    var recentPosts = require('./postDirectory.json');
    // limit to just the 3 most recent posts
    recentPosts = recentPosts.slice(0, 4);

    function formatDate(dateString) {
        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        const ordinalSuffix = (n) => {
        if (n % 10 === 1 && n !== 11) return n + "st";
        if (n % 10 === 2 && n !== 12) return n + "nd";
        if (n % 10 === 3 && n !== 13) return n + "rd";
        return n + "th";
        };
    
        const date = new Date(dateString);
        const day = ordinalSuffix(date.getDate());
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();
    
        return `${day} of ${month} ${year}`;
        
    }

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
            {recentPosts.map((post) => (
                <div 
                    key={post.id} // Assuming each post has a unique 'id'
                    className="border relative hover:bg-purple-100 rounded-xl m-1 overflow-hidden" 
                    onClick={() => window.location.href = "/posts/" + post.shortenedURL}
                    style={{cursor: 'pointer'}}
                >
                    <div className="absolute top-0 right-0 m-2">
                        <span className={`text-xs font-semibold py-1 px-2 uppercase rounded-full text-white text-left ${post.type === 'ideas-tips-tricks' ? 'bg-green-500' : post.type === 'reviews' ? 'bg-purple-600' : 'bg-orange-600'}`}>
                            {post.type === 'ideas-tips-tricks' ? 'Ideas, Tips, and Tricks' : post.type}
                        </span>
                    </div>
                
                    <img className="w-full object-cover rounded-t-lg h-40 md:h-64 mb-1" src={`${process.env.PUBLIC_URL}/images/uploads/${post.featuredImage}`} alt={post.title} />
                    <h3 className="text-lg font-bold p-1 text-left">
                        {post.title}
                    </h3>
                    <p className="text-sm p-1 text-left">{post.author} - {formatDate(post.date)}</p>
                    <p className="text-gray-500 text-sm p-1 text-left">{post.shortDescription}</p>
                </div>
            
            ))}
            {/* create a see all posts button */}
            <div className="col-span-full flex items-center justify-center mt-3 px-4 md:px-0">
                <button 
                    className="w-full md:w-auto bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded-full md:max-w-xs" 
                    onClick={() => window.location.href='/posts'}
                >
                    See all posts
                </button>
            </div>
        </div>

    );
}

export default Posts;
