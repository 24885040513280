import React, { useState, useEffect } from 'react';
import { useUser } from '@clerk/clerk-react';

const OpenInNewTabIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 inline-block ml-1">
    <path d="M10 6v2H5v11h11v-5h2v6a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h6zm11-3v8h-2V6.413l-7.793 7.794-1.414-1.414L17.585 5H13V3h8z" />
  </svg>
);

export const EmailAlerts = () => {
    const { user, isLoaded, isSignedIn } = useUser();
    const [formData, setFormData] = useState({
        email: '',
        phone: '',
        wine_alerts: false,
        sms_alerts: false,
        wine_threshold: '2.0',
        same_wine_day_interval: '14',
    });
    const [error, setError] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const [submitMessage, setSubmitMessage] = useState(null);
    const [phoneError, setPhoneError] = useState('');
    const [isSendingTestEmail, setIsSendingTestEmail] = useState(false);
    const [testEmailMessage, setTestEmailMessage] = useState(null);
    const [isSendingTestSMS, setIsSendingTestSMS] = useState(false);
    const [testSMSMessage, setTestSMSMessage] = useState(null);

    const isSubscribed = user?.publicMetadata?.subscriptionStatus === 'active';

    useEffect(() => {
        if (isLoaded && isSignedIn && user) {
            console.log("User data loaded:", user);
            try {
                setFormData({
                    email: user.publicMetadata.email || '',
                    phone: user.publicMetadata.phone || '',
                    wine_alerts: user.publicMetadata.wine_alerts || false,
                    sms_alerts: user.publicMetadata.sms_alerts || false,
                    wine_threshold: parseFloat(user.publicMetadata.wine_threshold || '2.0').toFixed(1),
                    same_wine_day_interval: user.publicMetadata.same_wine_day_interval || '14'
                });
            } catch (err) {
                console.error("Error setting initial state:", err);
                setError("Failed to load user settings");
            }
        }
    }, [isLoaded, isSignedIn, user]);

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const isValidPhone = (phoneNumber) => {
        const phoneRegex = /^\d{10}$/;
        return phoneRegex.test(phoneNumber);
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (name === 'phone') {
            const numericValue = value.replace(/\D/g, '');
            setFormData(prevData => ({
                ...prevData,
                [name]: numericValue,
                sms_alerts: isValidPhone(numericValue) // Automatically turn off SMS alerts if phone is invalid
            }));
            
            if (numericValue.length > 0 && !isValidPhone(numericValue)) {
                setPhoneError('Please enter a valid 10-digit mobile number.');
            } else {
                setPhoneError('');
            }
        } else {
            setFormData(prevData => ({
                ...prevData,
                [name]: type === 'checkbox' ? checked : value
            }));
        }
    };

    const handleToggleChange = (e) => {
        const { name, checked } = e.target;
        if (name === 'wine_alerts' && checked && !isValidEmail(formData.email)) {
            alert('Please enter a valid email address before enabling wine alerts.');
            return;
        }
        if (name === 'sms_alerts' && checked && !isValidPhone(formData.phone)) {
            setPhoneError('Please enter a valid phone number to enable SMS alerts.');
            return;
        }
        setFormData(prevData => ({
            ...prevData,
            [name]: checked
        }));
    };

    const handleSaveSettings = async (e) => {
        e.preventDefault();
        if (formData.sms_alerts && !isValidPhone(formData.phone)) {
            setPhoneError('Please enter a valid 10-digit mobile number to enable SMS alerts.');
            return;
        }
        setIsSaving(true);
        setSubmitMessage(null);
        try {
            const response = await fetch('https://ff-users.flightformula.workers.dev/update-metadata', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    userId: user.id,
                    metadata: formData
                }),
            });
            if (response.ok) {
                setSubmitMessage({ type: 'success', text: 'Settings updated successfully!' });
            } else {
                throw new Error('Failed to update settings');
            }
        } catch (error) {
            console.error('Error updating settings:', error);
            setSubmitMessage({ type: 'error', text: 'Failed to update settings. Please try again.' });
        } finally {
            setIsSaving(false);
        }
    };

    const handleSendTestEmail = async () => {
        setIsSendingTestEmail(true);
        setTestEmailMessage(null);
        try {
            const response = await fetch('https://ff-users.flightformula.workers.dev/send-test-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    userId: user.id,
                    recipient: formData.email,
                    subject: 'FlightFormula Wine Alerts Test',
                    body_text: 'This is a test email from FlightFormula.',
                    body_html: '<html><body><p>Hi! This is a test email from FlightFormula.</p><p>If you received this email, it means that your email alerts are working!</p> <p> I highly recommend replying to this email with a quick hello message, this will help ensure future emails are delivered to your inbox. (not spam!)</p></body></html>'
                }),
            });
            const data = await response.json();
            if (response.ok) {
                setTestEmailMessage({ type: 'success', text: 'Test email sent successfully!' });
            } else if (response.status === 403) {
                setTestEmailMessage({ type: 'error', text: 'You must have an active WinePlus subscription to send test emails.' });
            } else if (response.status === 429) {
                setTestEmailMessage({ type: 'error', text: `Rate limit exceeded. You can send another test email at ${new Date(data.nextAllowedTime).toLocaleString()}.` });
            } else {
                throw new Error(data.error || 'Failed to send test email');
            }
        } catch (error) {
            console.error('Error sending test email:', error);
            setTestEmailMessage({ type: 'error', text: error.message || 'Failed to send test email. Please try again.' });
        } finally {
            setIsSendingTestEmail(false);
        }
    };

    const handleSendTestSMS = async () => {
        setIsSendingTestSMS(true);
        setTestSMSMessage(null);
        try {
            const response = await fetch('https://ff-users.flightformula.workers.dev/test-sms', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    userId: user.id,
                    message: 'This is a test SMS from FlightFormula',
                    to: formData.phone,
                }),
            });
            const data = await response.json();
            if (response.ok) {
                setTestSMSMessage({ type: 'success', text: 'Test SMS sent successfully!' });
            } else if (response.status === 403) {
                setTestSMSMessage({ type: 'error', text: 'You must have an active WinePlus subscription to send test SMS.' });
            } else if (response.status === 429) {
                setTestSMSMessage({ type: 'error', text: `Ayo, slow down cowboy! You can send another test SMS at ${new Date(data.nextAllowedTime).toLocaleString()}.` });
            } else {
                throw new Error(data.error || 'Failed to send test SMS');
            }
        } catch (error) {
            console.error('Error sending test SMS:', error);
            setTestSMSMessage({ type: 'error', text: error.message || 'Failed to send test SMS. Please try again.' });
        } finally {
            setIsSendingTestSMS(false);
        }
    };

    const handleSubscribe = () => {
        const baseUrl = process.env.REACT_APP_STRIPE_BASE_URL;
        const stripeLink = `${baseUrl}?prefilled_email=${user.publicMetadata.email}&client_reference_id=${user.id}`;
        window.open(stripeLink, '_blank');
    };

    if (!isLoaded || !isSignedIn) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!isSubscribed) {
        return (
            <div className="max-w-2xl mx-auto p-4">
                <h1 className="text-2xl font-bold mb-4">Email Alerts</h1>
                <hr className="my-4" />
                <p className="mb-4 text-sm text-gray-600">
                    Email alerts are available for subscribed users only. Subscribe now to access this feature.
                </p>
                <button 
                    className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded inline-flex items-center"
                    onClick={handleSubscribe}
                >
                    Subscribe now
                    <OpenInNewTabIcon />
                </button>
            </div>
        );
    }

    return (
        <div className="max-w-2xl mx-auto p-4">            
            <h1 className="text-2xl font-bold mb-4">Alert Settings</h1>
            <hr className="my-4" />
            <form onSubmit={handleSaveSettings} className="space-y-6">
                <div className="bg-white rounded-lg">
                    <h2 className="text-lg font-semibold mb-3">Contact Information</h2>
                    <div className="space-y-4">
                        <div>
                            <label htmlFor="email" className="block mb-1 font-medium">Email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                className="w-full p-2 border rounded"
                                required
                            />
                            <p className="text-sm text-gray-500 mt-1">We'll send wine deal alerts to this email address.</p>
                        </div>

                        <div>
                            <label htmlFor="phone" className="block mb-1 font-medium">Mobile</label>
                            <div className="flex">
                                <div className="w-24 p-2 border rounded-l bg-gray-100 text-gray-700 font-medium">
                                    +61 (AU)
                                </div>
                                <input
                                    type="tel"
                                    id="phone"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleInputChange}
                                    className={`flex-grow p-2 border rounded-r ${phoneError ? 'border-red-500' : ''}`}
                                    placeholder="Enter your 10-digit mobile number"
                                    maxLength="10"
                                />
                            </div>
                            {phoneError && <p className="text-sm text-red-500 mt-1">{phoneError}</p>}
                            <p className="text-sm text-gray-500 mt-1">Optional: For receiving SMS alerts about wine deals. Currently only available for Australian mobile numbers.</p>
                        </div>
                    </div>
                </div>

                <div className="bg-white rounded-lg">
                    <h2 className="text-lg font-semibold mb-3">Alert Preferences</h2>
                    <div className="space-y-4">
                        <div className="flex items-center justify-between">
                            <div>
                                <span className="font-medium">Enable Email Alerts</span>
                                <p className="text-sm text-gray-500">Receive wine deal notifications via email</p>
                            </div>
                            <label className="relative inline-flex items-center cursor-pointer">
                                <input
                                    type="checkbox"
                                    name="wine_alerts"
                                    checked={formData.wine_alerts}
                                    onChange={handleToggleChange}
                                    className="sr-only peer"
                                    disabled={!isValidEmail(formData.email)}
                                />
                                <div className={`w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600 ${!isValidEmail(formData.email) ? 'opacity-50 cursor-not-allowed' : ''}`}></div>
                            </label>
                        </div>

                        <div className="flex items-center justify-between">
                            <div>
                                <span className="font-medium">Enable SMS Alerts</span>
                                <p className="text-sm text-gray-500">Get instant notifications via text message</p>
                            </div>
                            <label className="relative inline-flex items-center cursor-pointer">
                                <input
                                    type="checkbox"
                                    name="sms_alerts"
                                    checked={formData.sms_alerts}
                                    onChange={handleToggleChange}
                                    className="sr-only peer"
                                    disabled={!isValidPhone(formData.phone)}
                                />
                                <div className={`w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600 ${!isValidPhone(formData.phone) ? 'opacity-50 cursor-not-allowed' : ''}`}></div>
                            </label>
                        </div>
                    </div>
                </div>

                <div className="bg-white rounded-lg">
                    <h2 className="text-lg font-semibold mb-3">Alert Settings</h2>
                    <div className="space-y-4">
                        <div>
                            <label htmlFor="wine_threshold" className="block mb-1 font-medium">Wine Deal Threshold</label>
                            <select
                                id="wine_threshold"
                                name="wine_threshold"
                                value={formData.wine_threshold}
                                onChange={handleInputChange}
                                className="w-full p-2 border rounded"
                            >
                                {Array.from({ length: 11 }, (_, i) => (1.5 + i * 0.1).toFixed(1)).map(value => (
                                    <option key={value} value={value}>{value} cents per point / {(100 / parseFloat(value)).toFixed(2)} points per dollar</option>
                                ))}
                            </select>
                            <p className="text-sm text-gray-500 mt-1">You'll receive alerts for wine deals with a cents per point value equal to or below this threshold.</p>
                        </div>

                        <div>
                            <label htmlFor="same_wine_day_interval" className="block mb-1 font-medium">Repeat Alert Interval</label>
                            <select
                                id="same_wine_day_interval"
                                name="same_wine_day_interval"
                                value={formData.same_wine_day_interval}
                                onChange={handleInputChange}
                                className="w-full p-2 border rounded"
                            >
                                <option value="14">Every 14 days</option>
                                <option value="30">Every 30 days</option>
                                <option value="never">Never repeat</option>
                            </select>
                            <p className="text-sm text-gray-500 mt-1">How often you'll receive alerts for the same wine deal if it's still available.</p>
                        </div>
                    </div>
                </div>

                <div>
                    {submitMessage && (
                        <div className={`p-4 rounded mb-4 ${submitMessage.type === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
                            {submitMessage.text}
                        </div>
                    )}
                    <div className="flex flex-col sm:flex-row justify-between items-center space-y-4 sm:space-y-0 sm:space-x-4">
                        <button 
                            type="submit" 
                            className={`
                                w-full sm:w-auto bg-purple-600 text-white px-6 py-2 rounded
                                hover:bg-purple-700 transition-all duration-300 ease-in-out
                                ${isSaving ? 'opacity-75 cursor-not-allowed' : ''}
                            `}
                            disabled={isSaving}
                        >
                            {isSaving ? 'Saving...' : 'Save Settings'}
                        </button>
                        <button 
                            type="button" 
                            onClick={handleSendTestEmail}
                            className={`
                                w-full sm:w-auto bg-blue-500 text-white px-6 py-2 rounded
                                hover:bg-blue-600 transition-all duration-300 ease-in-out
                                ${isSendingTestEmail ? 'opacity-75 cursor-not-allowed' : ''}
                            `}
                            disabled={isSendingTestEmail || !formData.email}
                        >
                            {isSendingTestEmail ? 'Sending...' : 'Send Test Email'}
                        </button>
                        <button 
                            type="button" 
                            onClick={handleSendTestSMS}
                            className={`
                                w-full sm:w-auto bg-blue-500 text-white px-6 py-2 rounded
                                hover:bg-blue-600 transition-all duration-300 ease-in-out
                                ${isSendingTestSMS ? 'opacity-75 cursor-not-allowed' : ''}
                            `}
                            disabled={isSendingTestSMS || !formData.phone || !isValidPhone(formData.phone)}
                        >
                            {isSendingTestSMS ? 'Sending...' : 'Send Test SMS'}
                        </button>
                    </div>
                    {testEmailMessage && (
                        <div className={`mt-4 p-4 rounded ${testEmailMessage.type === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
                            {testEmailMessage.text}
                        </div>
                    )}
                    {testSMSMessage && (
                        <div className={`mt-4 p-4 rounded ${testSMSMessage.type === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
                            {testSMSMessage.text}
                        </div>
                    )}
                </div>
            </form>
        </div>
    );
};