import React from "react";

const VideoSection = ({ videoUrl }) => {
  return (
    <section className="mt-12">
      <h2 className="text-2xl font-bold mb-4">Latest Video</h2>
      <div className="relative w-full" style={{ paddingTop: "56.25%" }}>
        <iframe
          src={videoUrl}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          className="absolute top-0 left-0 w-full h-full"
        ></iframe>
      </div>
      <div className="mt-4 text-center">
        <a
          href="https://www.youtube.com/@FlightFormula"
          target="_blank"
          rel="noopener noreferrer"
          className="inline-block bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded-full transition-colors"
        >
          Watch more on YouTube
        </a>
      </div>
    </section>
  );
};

export default VideoSection;