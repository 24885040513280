import React from "react";

const ToolButton = ({ title, description, href, emoji }) => (
  <a
    href={href}
    className="block p-4 border border-purple-500 rounded-xl hover:bg-purple-100 transition-colors relative"
  >
    <div className="pr-8"> {/* Add padding-right to make room for emoji */}
      <h3 className="text-lg font-bold">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
    <span className="absolute right-4 top-1/2 transform -translate-y-1/2 text-5xl">
      {emoji}
    </span>
  </a>
);

export default ToolButton;