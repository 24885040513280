import React from 'react';

const TermsOfService = () => {
  return (
    <div className="max-w-3xl mx-auto px-4 py-8 font-sans text-gray-800">
      <h1 className="text-4xl font-bold text-purple-900 border-b-2 border-purple-500 pb-2 mb-6">Terms of Service for FlightFormula</h1>
      <p className="text-sm text-gray-600 mb-6">Last Updated: 5th of September 2024</p>

      <p className="mb-6">Welcome to FlightFormula! By accessing or using our website, you're agreeing to these Terms of Service. If you don't agree, you can go ahead and close this tab—no hard feelings. But seriously, you might want to stick around; we've got some good stuff here.</p>

      <h2 className="text-2xl font-semibold text-purple-700 mt-8 mb-4">1. Acceptance of Terms</h2>
      <p className="mb-6">By using FlightFormula, you're basically signing a digital pinky swear that you'll follow our rules. If you break them, we might have to uninvite you from the party (i.e., terminate your access).</p>

      <h2 className="text-2xl font-semibold text-purple-700 mt-8 mb-4">2. Use of the Website</h2>
      <p className="mb-4">FlightFormula is here to make your travel planning as smooth as that perfect in-flight nap. But please, no funny business:</p>
      <ul className="list-disc pl-6 mb-6">
        <li className="mb-2">Don't hack, cheat, or otherwise make a mess of things.</li>
        <li className="mb-2">Don't use our website while piloting a plane. Focus on flying, Captain!</li>
        <li className="mb-2">Don't scrape data unless you've got our written permission. It's like asking before you borrow a pen—just polite.</li>
      </ul>

      <h2 className="text-2xl font-semibold text-purple-700 mt-8 mb-4">3. Your Account</h2>
      <p className="mb-6">If you create an account with us, you're responsible for keeping your password a secret. If you tell anyone, even your cat, we're not liable for anything that goes wrong. It's your account; keep it secure.</p>

      <h2 className="text-2xl font-semibold text-purple-700 mt-8 mb-4">4. Content Ownership</h2>
      <p className="mb-6">We own the cool stuff we create (designs, logos, content), and you own your content. You give us a license to use your content to make FlightFormula even more awesome. But don't worry, we won't sell your travel secrets to the highest bidder.</p>

      <h2 className="text-2xl font-semibold text-purple-700 mt-8 mb-4">5. Flight Information</h2>
      <p className="mb-6">While we do our best to provide accurate flight information, we can't control the whims of airlines, weather gods, or gremlins on the wing. Use the info at your own risk. We're not liable if you miss your flight, unless it's because we personally threw a banana peel on the jetway.</p>

      <h2 className="text-2xl font-semibold text-purple-700 mt-8 mb-4">6. Limitation of Liability</h2>
      <p className="mb-6">We're here to help, but if something goes wrong (like your computer spontaneously combusting), our liability is limited. We're not responsible for any direct, indirect, incidental, or consequential damages. Basically, if your laptop explodes because of FlightFormula, that's on you.</p>

      <h2 className="text-2xl font-semibold text-purple-700 mt-8 mb-4">7. Changes to These Terms</h2>
      <p className="mb-6">We might update these terms from time to time, like upgrading from economy to business class. If we make changes, we'll let you know—probably through an email that you'll ignore. But hey, at least we tried.</p>

      <h2 className="text-2xl font-semibold text-purple-700 mt-8 mb-4">8. Termination</h2>
      <p className="mb-6">We can terminate or suspend your access if you violate these terms or if we just get a weird vibe. We're not saying we're judging, but we're definitely judging.</p>

      <h2 className="text-2xl font-semibold text-purple-700 mt-8 mb-4">9. Governing Law</h2>
      <p className="mb-6">These terms are governed by the laws of Australia. Any disputes will be handled in Australia, unless we can resolve it with a friendly game of rock-paper-scissors.</p>

      <h2 className="text-2xl font-semibold text-purple-700 mt-8 mb-4">10. Contact Us</h2>
      <p className="mb-6">If you have questions, concerns, or just want to chat, you can reach us at legalstuff@flightformula.com. But remember, we're not flight attendants—we can't get you a drink or an extra pillow.</p>

      <p className="mt-8 text-gray-700">By using FlightFormula, you acknowledge that you've read these terms, had a little laugh, and agree to follow them. Now, let's get you to your next destination!</p>
    </div>
  );
};

export default TermsOfService;
